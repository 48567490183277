// MIKADO, TAZUKI, CARDAKINA, BLIXCARD
const brandForLocalhost = 'BLIXCARD'

const brandsMetadata = {
  BLIXCARD: {
    domain: 'blixcard.com',
    backendForLocalhost: 'https://pay-dev.blixcard.com',
    // backendForLocalhost: 'https://pay-sandbox.blixcard.com',
    termsUrl: '', // TODO https://tazicard.com/terms-and-conditions.pdf
    languages: ['en', 'de', 'es', 'fr', 'hr', 'it', 'pl', 'pt', 'fi', 'sv', 'ja', 'ko'],
    blixcard: true,
    head: {
      title: 'Blixcard',
      favicon: '/favicon-blixcard.ico',
      manifest: '/manifest-blixcard.json'
    }
  },

  MIKADO: {
    domain: 'mikadocard.com',
    prefix: 'mikado-',
    backendForLocalhost: 'https://mikado-dev.banky.io',
    redirect: 'https://mikadocard.com/auth/log-in',
    termsUrl: 'https://mikadocard.com/terms-and-conditions.pdf',
    languages: ['en', 'de', 'es', 'fr', 'hr', 'it', 'pl', 'pt', 'fi', 'sv', 'ja', 'ko'],
    head: {
      title: 'MikadoCard',
      favicon: '/favicon-mikado.png',
      manifest: '/manifest-mikado.json'
    }
  },

  TAZUKI: {
    domain: 'tazukicard.com',
    prefix: 'tazuki-',
    backendForLocalhost: 'https://tazuki-dev.banky.io',
    // backendForLocalhost: 'https://tazuki-sandbox.banky.io',
    redirect: 'https://tazukicard.com/auth/log-in',
    termsUrl: 'https://tazukicard.com/terms-and-conditions.pdf',
    languages: ['en', 'de', 'es', 'fr', 'hr', 'it', 'pl', 'pt', 'fi', 'sv', 'ja', 'ko'],
    head: {
      title: 'TazukiCard',
      favicon: '/favicon-tazuki.png',
      manifest: '/manifest-tazuki.json'
    }
  },

  CARDAKINA: {
    domain: 'cardakina.com',
    prefix: 'cardakina-',
    backendForLocalhost: 'https://cardakina-dev.banky.io',
    redirect: 'https://cardakina.com/auth/log-in',
    termsUrl: 'https://cardakina.com/terms-and-conditions.pdf',
    languages: ['en', 'de', 'es', 'fr', 'hr', 'it', 'pl', 'pt', 'fi', 'sv', 'ja', 'ko'],
    head: {
      title: 'CardAkina',
      favicon: '/favicon-cardakina.png',
      manifest: '/manifest-cardakina.json'
    }
  }
}

const host = typeof window !== 'undefined' ? window.location.host : ''

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
)

export const isDevelopment = host.indexOf('-dev.') > -1
export const isSandbox = host.indexOf('-sandbox.') > -1
export const isProduction = typeof window !== 'undefined' && !isLocalhost && !isSandbox && !isDevelopment

const determineBrandByUrl = () => {
  const keyByPrefix = Object.keys(brandsMetadata).find(
    key => brandsMetadata[key].prefix && host.indexOf(brandsMetadata[key].prefix) > -1
  )
  if (keyByPrefix) {
    return keyByPrefix
  }

  return Object.keys(brandsMetadata).find(
    key => brandsMetadata[key].domain && host.indexOf(brandsMetadata[key].domain) > -1
  )
}

const brand = isLocalhost ? brandForLocalhost : determineBrandByUrl()

const settings = {
  brand,
  blixcard: false,
  backendForLocalhost: 'https://pay-dev.banky.io',
  redirect: '',
  languages: ['en'],
  head: null,
  ...brandsMetadata[brand]
}

export default settings

const createOrUpdateLink = (rel, href) => {
  let link = document.querySelector("link[rel~='" + rel + "']")
  if (!link) {
    link = document.createElement('link')
    link['rel'] = rel
    document.getElementsByTagName('head')[0].appendChild(link)
  }
  link['href'] = href
}

export const updateDocumentHead = () => {
  if (settings.head) {
    document.title = settings.head.title
    createOrUpdateLink('icon', settings.head.favicon)
    createOrUpdateLink('apple-touch-icon', settings.head.favicon)
    createOrUpdateLink('manifest', settings.head.manifest)
  }
}
